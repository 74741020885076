<template>
  <div>
    <bread-crumb :crumbData="['链接管理', '新增']" :chooseCrumbData="2" @close="onClose"></bread-crumb>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
      style="padding: 50px; width: 70%">
      <el-form-item label="标题：" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="链接图片：">
        <el-upload class="avatar-uploader" :action="$http.defaults.baseURL + '/uploads_images/upload'"
          :headers="{ token: token }" :show-file-list="false" :on-success="handleAvatarSuccessImg">
          <img v-if="ruleForm.imageUrl" :src="ruleForm.imageUrl" class="avatar" />
          <el-button size="small" type="primary" class="uploadBtu">选择图片</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="链接视频：">
        <el-upload class="upload-video" :action="$http.defaults.baseURL + '/uploads_images/upload'"
          :headers="{ token: token }" :before-upload="beforeUploadVideo" :on-success="handleAvatarSuccessVideo">
          <video v-if="ruleForm.videoUrl" :src="ruleForm.videoUrl" controls></video>
          <el-button size="small" type="primary" class="uploadBtu">选择视频</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="链接图标：">
        <el-upload class="avatar-uploader" :action="$http.defaults.baseURL + '/uploads_images/upload'"
          :headers="{ token: token }" :show-file-list="false" :on-success="handleAvatarSuccessIcon">
          <img v-if="ruleForm.IconUrl" :src="ruleForm.IconUrl" class="avatar" />
          <el-button size="small" type="primary" class="uploadBtu">选择图标</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="链接标签：">
        <el-select v-model="ruleForm.tag" placeholder="无">
          <el-option v-for="item in tages" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="链接位置：">
        <el-select v-model="ruleForm.position" placeholder="链接位置">
          <el-option v-for="item in locationes" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联分类：">
        <el-input v-model="ruleForm.ranked" placeholder="只针对分类链接位生效"></el-input>
      </el-form-item>
      <el-form-item label="类型：" prop="type">
        <el-radio-group v-model="ruleForm.type">
          <el-radio v-for="item in types" :key="item" :label="item"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="链接ID号：">
        <el-input v-model="ruleForm.linkId" placeholder="请输入链接资料id号"></el-input>
      </el-form-item>
      <el-form-item label="小程序：">
        <el-input v-model="ruleForm.appid" placeholder="请输入appid号"></el-input>
      </el-form-item>
      <el-form-item label="路径：">
        <el-input v-model="ruleForm.path" placeholder="请输入path号"></el-input>
      </el-form-item>
      <el-form-item label="颜色：">
        <div style="display: flex; align-items: center">
          <el-input v-model="ruleForm.color" clearable style="margin-right: 5px"></el-input>
          <el-color-picker v-model="ruleForm.color" color-format="hex" :predefine="predefineColors">
          </el-color-picker>
        </div>
      </el-form-item>
      <el-form-item label="排序：">
        <el-input v-model="ruleForm.sort"></el-input>
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-radio-group v-model="ruleForm.status">
          <el-radio v-for="item in statuses" :key="item" :label="item.label" :value="item.value"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      token: localStorage.getItem("token") ||
        sessionStorage.getItem("token"),
      tages: ["无"],
      locationes: [
        "套餐置顶",
        "我的订单",
        "招商链接",
        "首页领券",
        "首页秒杀",
        "砍价置顶",
        "秒杀置顶",
        "拼团置顶",
        "活动横幅",
        "菜箱横幅",
        "新品横幅",
        "分类横幅",
        "首页置顶",
      ],
      types: [
        "商品",
        "分类",
        "活动",
        "优惠券",
        "小程序",
        "外部链接",
        "外部小程序",
      ],
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "#c7158577",
      ],
      statuses: [{
        label: "正常",
        value: 1
      }, {
        label: "关闭",
        value: 2
      }],
      ruleForm: {
        id: 0,
        title: "",
        imageUrl: "",
        videoUrl: "",
        IconUrl: "",
        tag: "无",
        position: "套餐置顶",
        ranked: "",
        type: "商品",
        linkId: "",
        appid: "",
        path: "",
        color: "",
        sort: "",
        status: "正常",
      },
      rules: {
        title: [{ required: true, message: "必填字段", trigger: "blur" }],
        type: [{ required: true, message: "必填字段", trigger: "change" }],
        status: [{ required: true, message: "必填字段", trigger: "change" }],
      },
    };
  },
  mounted() { },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(this.ruleForm);
          // alert("submit!");
          let { data: res } = await this.$http.post("/content/Link_Management/save", this.ruleForm);
          if (res.status == "success") {
            this.$message.success("新增成功");
            this.$emit("close", 0);
          } else {
            this.$message.success("新增失败")
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleAvatarSuccessImg(res, file) {
      if (res.status == "success") {
        this.ruleForm.imageUrl = res.path
      }
    },
    beforeUploadVideo(file) {
      const isVideo = file.type.startsWith("video/");
      if (!isVideo) {
        this.$message.error("请上传视频文件");
        return false;
      }
      return true;
    },
    handleAvatarSuccessVideo(response) {
      if (res.status == "success") {
        this.ruleForm.videoUrl = res.path
      }
    },
    handleAvatarSuccessIcon(res, file) {
      if (res.status == "success") {
        this.ruleForm.IconUrl = res.path
      }
    },
  },
};
</script>
<style scoped lang="less">
.avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  border: 1px solid #dddddd;
  pointer-events: none !important;
}

/deep/ .avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.uploadBtu {
  pointer-events: auto !important;
  margin-top: 15px !important;
}
</style>
