<template>
  <div>
    <div v-if="isChild == 0">
      <bread-crumb :crumbData="['链接管理']" :chooseCrumbData="1"></bread-crumb>
      <!-- <search-case :searchData="searchData" :chooseSearch="3"></search-case> -->


      <el-row style="padding: 20px;display: flex; align-items: center;border-bottom: 1px solid #e5e5e5;">
        <el-col :span="6">
          <span>创建时间：</span>
          <el-date-picker v-model="t1" format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="2">
          <el-select v-model="query.status" clearable placeholder="状态">
            <el-option v-for="item in optiones1" :key="item" :label="item.value" :value="item.label">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-select v-model="query.position" clearable placeholder="链接位">
            <el-option v-for="item in optiones2" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-input v-model="query.search" placeholder="输入要查询的手机号,姓名,地址" style="margin-right: 3px;"></el-input>
          <el-button type="success" size="mini" @click="handleQuery()">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span>查询</span>
            </div>
          </el-button>
        </el-col>
      </el-row>

      <div style="
          padding: 10px 20px;
          border-bottom: 1px solid #eee;
          display: flex;
          align-items: center;
        ">
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="isChild = 1">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 3px">新增素材</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini" @click="remove">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 3px">批量删除</span>
            </div>
          </el-button>
        </div>
      </div>

      <div style="padding: 20px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
          @selection-change="handleSelectionChange" style="width: 100%">
          <el-table-column type="selection" width="44"> </el-table-column>
          <el-table-column prop="sort" sortable label="序号"></el-table-column>
          <el-table-column prop="create_time" sortable label="创建日期"></el-table-column>
          <el-table-column prop="title" sortable label="名称"></el-table-column>
          <el-table-column prop="types" sortable label="类型"></el-table-column>
          <el-table-column prop="linkid" sortable label="链接"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
              <span style="margin: 0 10px"></span>
              <i class="el-icon-delete poiner" @click="handleDelete(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
        <pagination :handleQuery="handleQuery" :currentPage="query.currentPage" :currentLength="query.currentLength"
          :total="total">
        </pagination>
      </div>
    </div>

    <add-group v-if="isChild == 1" @close="onClose" ref="add"></add-group>
  </div>
</template>
<script>

import pagination from "@/components/pagination/index.vue";
import addGroup from "./childrens/linkAddGroup.vue";
export default {
  components: {
    addGroup, pagination
  },
  data() {
    return {
      optiones1: [{ label: 1, value: "正常" }, { label: 2, value: "关闭" }],
      optiones2: [
        "套餐置顶",
        "我的订单",
        "招商链接",
        "首页领券",
        "首页秒杀",
        "砍价置顶",
        "秒杀置顶",
        "拼团置顶",
        "活动横幅",
        "菜箱横幅",
        "新品横幅",
        "分类横幅",
        "首页置顶",
      ],
      t1: "",
      query: {
        firstTime: "",
        lastTime: "",
        status: 1,
        position: null,
        search: "",
        currentPage: 1,
        currentLength: 10
      },
      searchData: {
        date: "",
        option1: "",
        selectPlace1: "状态",
        optiones1: ["正常", "关闭"],
        option2: "",
        selectPlace2: "链接位",
        optiones2: [
          "套餐置顶",
          "我的订单",
          "招商链接",
          "首页领券",
          "首页秒杀",
          "砍价置顶",
          "秒杀置顶",
          "拼团置顶",
          "活动横幅",
          "菜箱横幅",
          "新品横幅",
          "分类横幅",
          "首页置顶",
        ],
        searchPlace: "输入要查询的内容",
        QueryContent: "",
        searchBtn1: true,
      },

      tableData: [],
      total: 0,
      // multipleSelection: [],
      // isResult: false,
      // tableFinshNum: 0,

      isChild: 0,
    };
  },
  mounted() {
    this.handleQuery()
    // if (this.tableData.length == 0) {
    //   this.isResult = false;
    // } else {
    //   this.isResult = true;
    //   this.tableFinshNum = this.tableData.length;
    // }
  },
  computed: {},
  watch: {
    t1(n) {
      this.query.firstTime = n[0]
      this.query.lastTime = n[1]
    }
  },
  methods: {
    async handleQuery(page = 1) {
      this.query.currentPage = page;
      let query = this.query;

      console.log(query);
      let { data } = await this.$http.post("/content/Link_Management/lst", query);
      this.tableData = data.data;
      this.total = data.total;
      // console.log(data);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEdit(row) {
      row.imageUrl = row.imgae;
      row.videoUrl = row.video;
      row.iconUrl = row.icon;
      let statuses = [{
        label: "正常",
        value: 1
      }, {
        label: "关闭",
        value: 2
      }]
      this.status = statuses.find(el => el.label == row.status);
      // this.$refs.add.ruleForm = row
      this.isChild = 1;
      // console.log(this.isChild)
      this.$nextTick(() => {
        this.$refs.add.ruleForm = row;
        // console.log(this.$refs.add.ruleForm, row);
      })
    },
    async handleDelete({ id }) {
      // console.log(row);
      let res = (await (this.$http.post("/content/Link_Management/del", { idArr: id }))).data;
      if (res.status = "success") {
        this.$message.success("删除成功");
        this.handleQuery()
      } else {
        this.$message("操作失败");
      }
      // console.log(res);
    },
    async remove({ id }) {
      // console.log(row);
      if (this.multipleSelection.length == 0) return;
      let res = (await (this.$http.post("/content/Link_Management/del", { idArr: this.multipleSelection.map(item => item.id).join(',') }))).data;
      if (res.status = "success") {
        this.$message.success("删除成功");
        this.handleQuery()
      } else {
        this.$message("操作失败");
      }
      // console.log(res);
    },

    onClose(data) {
      this.isChild = data;
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  padding: 20px;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin: 0 5px;
}

/deep/ .cell {
  padding: 0 !important;
}
</style>
