import { render, staticRenderFns } from "./linkManage.vue?vue&type=template&id=6483751e&scoped=true&"
import script from "./linkManage.vue?vue&type=script&lang=js&"
export * from "./linkManage.vue?vue&type=script&lang=js&"
import style0 from "./linkManage.vue?vue&type=style&index=0&id=6483751e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6483751e",
  null
  
)

export default component.exports